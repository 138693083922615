<template>
<q-page padding>
  <router-view />
</q-page>
</template>

<script>
export default {
    name: 'Login_Index'
}
</script>>